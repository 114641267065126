import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Styles
import styles from "./Tags.module.scss";

const Tags = ({
  isLoading,
  tagList,
  onSelectTags
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [updateTags, setUpdateTags] = useState(false);
  const [negateTagQuery, setNegateTagQuery] = useState(false);
  const [disableTagAction, setDisableTagAction] = useState(false);

  const { disableViewerActions } = useSelector((state) => state.viewer);
  const { filteredTags } = useSelector((state) => state.generalRV);

  const notTagged = {
    tagID: -1,
    name: 'Select Not Tagged',
  };

  const handleCheckboxChange = (tagID) => {
    setNegateTagQuery(false);
  
    const isNotTaggedSelected = tagID === notTagged.tagID;
    const isCurrentlySelected = selectedTags.includes(tagID);
  
    if (isNotTaggedSelected && !isCurrentlySelected) {
      setSelectedTags([tagID]);
      setNegateTagQuery(true);
    } else {
      setSelectedTags((prevSelectedTags) => {
        var updatedTags = prevSelectedTags;

        if (selectedTags.includes(notTagged.tagID))
          updatedTags = updatedTags.filter((id) => id !== notTagged.tagID);
  
        return isCurrentlySelected
          ? updatedTags.filter((id) => id !== tagID)
          : [...updatedTags, tagID];
      });
    }
  
    setUpdateTags(true);
  };  

  useEffect(() => {
    if (updateTags) {
      onSelectTags(selectedTags, negateTagQuery);
      setUpdateTags(false);
    }
  }, [selectedTags]);

  useEffect(() => {
    setSelectedTags(filteredTags);
  }, [filteredTags]);

  useEffect(() => {
    setDisableTagAction(disableViewerActions);
  }, [disableViewerActions]);

  const renderTags = (tags) => {
    const tagsWithDefault = [notTagged, ...tags];
    return tagsWithDefault.map((tag) => (
      <div key={tag.tagID} className={clsx(styles["tag-item"])}>
        <input
          type="checkbox"
          className={clsx(styles["tag-checkbox"])}
          value={tag.tagID}
          id={`tagCheckbox_${tag.tagID}`}
          checked={selectedTags.includes(tag.tagID)}
          onChange={() => handleCheckboxChange(tag.tagID)}
          disabled={disableTagAction}
        />
        {tag.tagID === -1 ? (
          <span className={clsx("e-icons e-circle-remove", clsx(styles["not-tagged"]))}></span>
        ) : (
          <span
            className={clsx(styles["tag-color"])}
            style={{ backgroundColor: tag.color }}
            title={tag.name}
          />
        )}
        <label htmlFor={`tagCheckbox_${tag.tagID}`}>
          <span className={clsx(styles["tag-name"])}>
            {tag.name}
          </span>
        </label>
      </div>
    ));
  };  

  return (
    <>
      {!isLoading ? (
        <div className={clsx(styles["container"])}>
          {renderTags(tagList)}
        </div>
      ) : (
        <div className={styles["spinner-container"]}>
          <Spinner
            className={styles["spinner-loading"]}
            animation="border"
            variant="primary"
            role="status"
          />
        </div>
      )}
    </>
  );
};

Tags.propTypes = {
  isLoading: PropTypes.bool,
  tagList: PropTypes.array,
  onSelectTags: PropTypes.func,
};

export default Tags;