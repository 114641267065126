const TYPE_CLICK = {
  next: "next",
  previous: "prev",
  last: "last",
  first: "first",
  up: "up",
  down: "down",
};

const QUICK_SELECT_ITEM = {
  dataSources: {
    label: "Data Sources",
    labelShort: "Data Source",
    value: "dataSources",
  },
  dataTypes: {
    label: "Data Types",
    labelShort: "Data Type",
    value: "dataTypes",
  },
  entities: {
    label: "People",
    labelShort: "Person",
    value: "entities",
  },
  participants: {
    label: "Participants",
    labelShort: "Participant",
    value: "participants",
  },
  tags: {
    label: "Tags",
    labelShort: "Tag",
    value: "tags",
  },
  emojis: {
    label: "Emojis",
    labelShort: "Emoji",
    value: "emojis",
  },
  locationTypes: {
    label: "Location Types",
    labelShort: "Location Type",
    value: "locationTypes",
  },
  contacts: {
    label: "Contacts",
    labelShort: "Contact",
    value: "contacts",
  },
  identifiers: {
    label: "Identifiers",
    labelShort: "Identifier",
    value: "identifier",
  },
};

const PARAMETERS = {
  title: "title",
  docID: "docID",
  id: "id",
  type: "type",
  typeLabel: "typeLabel",
  totalMessage: "totalMessage",
  participants: "participants",
  dateTime: "dateTime",
  createdDate: "createdDate",
  updatedDate: "updatedDate",
  lastConnection: "lastConnection",
  lastAutoConnection: "lastAutoConnection",
  dateLastUsed: "dateLastUsed",
  expirationDate: "expirationDate",
  creationTime: "creationTime",
  dateFirst: "dateFirst",
  dateLast: "dateLast",
  lastVisited: "lastVisited",
  duration: "duration",
  emails: "emails",
  dateFirstEmail: "dateFirstEmail",
  dateLastEmail: "dateLastEmail",
  attachment: "attachment",
  contactName: "contactName",
  profilePicture: "profilePicture",
  deletedState: "deletedState",
  messageCount: "messageCount",
  itemsCount: "itemsCount",
  locations: "locations",
  from: "locations",
  to: "locations",
  source: "source",
  startTime: "startTime",
  endTime: "endTime",
  image: "image",
  projectName: "projectName",
  dataSource: "dataSource",
  sourceType: "sourceType",
  custodianName: "custodianName",
  custodianSources: "custodianSources",
  dataTypes: "dataTypes",
  entities: "entities",
  sourceFile: "sourceFile",
  deviceName: "deviceName",
  analystName: "analystName",
  phoneOwner: "phoneOwner",
  file: "file",
  listFile: "listFile",
  projectId: "projectId",
  latitude: "latitude",
  longitude: "longitude",
  address: "address",
  dataSourceID: "dataSourceID",
  description: "description",
  attachmentCount: "attachmentCount",
  attachmentFileSize: "attachmentFileSize",
  sourceApplication: "sourceApplication",
};

const DATA_INFO_LABEL = {
  documentID: "Document ID",
  type: "Type",
  callType: "Call Type",
  dateTime: "Date/Time",
  direction: "Direction",
  duration: "Duration",
  status: "Status",
  sourceApplication: "Source Application",
  deletedStatus: "Deleted Status",
  contentType: "Content Type",
  title: "Title",
  contactType: "Contact Type",
  contactName: "Contact Name",
  createdDate: "Created Date",
  updatedDate: "Updated Date",
  profilePicture: "Profile Picture",
  fileType: "File Type",
  fileName: "File Name",
  folder: "Folder",
  lastAutoConnection: "Last Auto Connection",
  lastConnection: "Last Connection",
  latitude: "Latitude",
  longitude: "Longitude",
  locationTime: "Location Time",
  address: "Address",
  linkName: "Link Name",
  linkType: "Link Type",
  lastVisited: "Last Visited",
  visitCount: "Visit Count",
  url: "URL",
  company: "Company",
  creditCardNumber: "Credit card number",
  nameOnCard: "Name on card",
  expirationDate: "Expiration date",
  dateLastUsed: "Date last used",
  name: "Name",
  participants: "Participants",
  dataSource: "Data Source",
};

const DROPDOWN_LABEL = {
  page: "Page",
  docId: "Record ID",
};

const METHOD_API = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

const COMMON_TEXT = {
  unknown: "Unknown",
  noMore: "No more",
  default: "-",
  percent: "%",
  selected: "selected",
  available: "available",
  yes: "Yes",
  deleted: "deleted",
  no: "No",
  intact: "intact",
  or: "OR",
  and: "AND",
  space: " ",
  private: "Private",
  global: "Global",
  edit: "edit",
  add: "add",
  create: "create",
  active: "active",
  admin: "ADM",
  remove: "remove",
  skip: "Skip",
  cancel: "Cancel",
  delete: "Delete",
  notification: "Notification",
  confirm: "Confirm"
};

const OPTION_SORT = {
  newToOld: "New to Old",
  oldToNew: "Old to New",
};

const OPTION_SELECT_MESSAGE = {
  all: "All",
  five: 5,
  four: 4,
  three: 3,
  two: 2,
  one: 1,
};

const DISPLAY_TYPE = {
  preview: "preview"
};

const AXIOS_RESPONSE_CODE = {
  timeOut: "ECONNABORTED",
};

const EVENT_KEY_LISTENER = {
  timeOut: "timeOut",
  click: "click",
  keydown: "keydown",
  scroll: "scroll",
  mousedown: "mousedown",
  touchstart: "touchstart",
  offline: "offline",
  beforeunload: "beforeunload",
  popstate: "popstate",
  storage: "storage",
  ready: "ready",
};

const EVENT_LISTENER = {
  reload: "reload",
};

const KEY_CODE = {
  backSpace: 8,
  enter: 13,
  escape: 27,
  space: 32,
  arrowLeft: 37,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40,
  tab: 9,
};

const PAGE_NAME = {
  eventTimeline: "Event Timeline",
  intelligentSearch: "Intelligent Search",
  viewDetail: "View Detail",
  eventTimelineDetail: "EventTimeline Detail",
  conceptVisualizer: "Concept Visualizer",
  savedSearches: "Saved Searches",
  dataSources: "Data Sources",
  communicationAnalyzer: "Communication Analyzer",
  intelligentAnalysis: "Intelligent Analysis",
  managePeople: "Manage People",
  manageIdentifiers: "Manage Identifiers",
  timelineAnalyzer: "Timeline Analyzer",
  people: "People",
  streems: "Streems",
  search: "Search",
  insights: "Insights",
  exportManagement: "Export Management",
  searchResult: "Search Result",
  tagManagement: "Tag Management",
  searchTerms: "Search Terms",
  exportData: "Export Data",
  addUser: "Add User",
  administration: "Administration",
};

const PATH_NAME = {
  dashboard: "dashboard",
  communicationAnalyzer: "communication-analyzer",
  communicationReview: "communication-review",
  chatEmailReview: "chat-email-review",
  emailReview: "email-review",
  streems: "streems",
  searchTerms: "search-terms",
  clientManagement: "client-management",
  importManagement: "import-management",
  importTool: "import-tool",
  userAdministration: "user-administration",
  insights: "insights",
  intelligentSearch: "intelligent-search",
  savedSearches: "saved-searches",
  tagManagement: "tag-management",
  matters: "matters",
  error: "error",
  userProfile: "user-profile",
  changePassword: "change-password",
  people: "people",
  dataSources: "data-sources",
  eventTimeline: "event-timeline",
  exportManagement: "export-management",
  createExport: "create-export",
  configuration: "configuration",
  itemDetail: "item/detail",
  detail: "detail",
  searchResult: "result",
};

const PATHNAME_ITEMS = {
  leftPath: "*",
  dashboard: "/",
  login: "/login",
  authLogin: "/auth/login",
  matters: "/matters",
  userManagement: "/user-administration",
  clientManagement: "#",
  importManagement: "/import-management",
  importTool: "/import-tool",
  exportTool: "/export-tool",
  tagManagement: "/tag-management",
  userProfile: "/user-profile",
  error500: "/error-500",
  error400: "/error-400",
  error401: "/error-401",
  error403: "/error-403",
  error423: "/error-423",
  error404: "/error-404",
};

const TYPE_ICON = {
  locationLabel: "locationLabel",
  dataSourceType: "dataSourceType",
};

const USER_AGENT_TEXT = {
  ie: "MSIE ",
  edge: "Edg",
  google: "Google Inc.",
  iosChrome: "CriOS",
  fireFox: "firefox",
};

const ARCHIVE_MSG = {
  request: "Please confirm archive request for this matter.",
  archive: "Please confirm archive creation for this matter.",
  restore: "Please confirm restoration for this matter.",
  active:
    "This matter will be changed status to Active when approving. Please make sure the database creation is completed.",
};

const ARCHIVE_STATUS = {
  active: {
    status: "Active",
    actionLabel: "Request Archive",
    action: "request",
  },
  pending: {
    status: "Pending",
    actionLabel: "Archive",
    action: "archive",
  },
  creating: {
    status: "Creating",
    actionLabel: "Active",
    action: "active",
  },
  deleted: {
    status: "Deleted",
    actionLabel: "Restore",
    action: "restore",
  },
  archived: {
    status: "Archived",
    actionLabel: "Restore",
    action: "restore",
  },
};

const ARCHIVE_TYPE = {
  archived: "Archived",
  pending: "Pending",
  creating: "Creating",
  active: "Active",
  restore: "Restore",
  archive: "Archive",
  request: "Request Archive",
  deleted: "Deleted",
};

const ARCHIVE_ACTION = {
  restore: "restore",
  request: "request",
  active: "active",
  archive: "archive",
  reject: "reject",
};

const COLORS = {
  main: "#1580ef",
  blue: "#1580EF",
  hoverSelect: "#DDEBFF",
  black: "#000",
  greyGain: "#d9d9d9",
  white: "#FFF",
  red: "#d90000",
  yellow: "yellow",
};

const PRINT_SCREEN_NAME = {
  dashboard: "Dashboard",
  dataSources: "Data Sources",
  people: "People",
  activeProject: "Active Matters",
  projectManagement: "Matter Management",
  projectView: "Matter View",
  streems: "Streems",
  chatReview: "Chat Review",
  eventTimelineDetail: "Event Timeline Detail",
  communicationAnalyzer: "Communication Analyzer",
  manageIdentifiers: "Manage Identifiers",
};

const TIME = {
  timeSeconds: 1000,
};

const PERCENTAGE = {
  oneHundred: 100,
};

const PAGINATION = {
  perPage: 1000,
  recordPerPageTable: 50,
  firstIndexOnPage: 0,
  lastIndexOnPage: 49,
  screen: { lager: 20, medium: 15, small: 10 },
  pageNumberDefault: 1,
};

const ROLE_BASED_EMAIL = {
  from: "From",
  to: "To",
};

const IMPORT_TYPE = {
  database: "Import to Database",
  blobStorage: "Import to Blob Storage",
};

const STATUS_IMPORT_BLOB = {
  ready: 1,
  inprogress: 2,
  pause: 3,
};

const ENVIRONMENT_SITE = {
  development: "development",
  uat: "uat",
  demo: "demo",
  production: "production",
};

const DEFAULT_NAME = {
  downstreemAdmin: "Downstreem Admin",
};

const TOAST_MESSAGE_IMPORT_NATIVES = {
  success: "Successfully uploaded native files!",
  failed: "Failed to upload native files.",
  cancel: "Upload canceled.",
};

const TOAST_MESSAGE_IMPORT_DATA = {
  success: "The import was successful!",
  failed: "The import failed.",
};

const TOAST_MESSAGE_INDEX_REBUILD_STARTED = "The index for this matter is being rebuilt.";
const TOAST_MESSAGE_INDEX_REBUILD_DONE = "The index for this matter has rebuilt, and the results may have changed. Please reload the page to see the updated results.";

const SIGNALR_CLIENT_FUNCTIONS = {
  ExportNotification: "ExportNotification",
  ImportStatusNotification: "ImportStatusNotification",
  ImportStageNotification: "ImportStageNotification",
  ImportLogNotification: "ImportLogNotification",
  MatterIndexRebuildStarted: "MatterIndexRebuildStarted",
  MatterIndexRebuildDone: "MatterIndexRebuildDone",
  MassTagCompleted: "MassTagCompleted",
  FilterTagCompleted: "FilterTagCompleted",
  RunSearchTermsCompleted: "RunSearchTermsCompleted"
};

const SIGNALR_SERVER_FUNCTIONS = {
  SubscribeToMatter: "SubscribeToMatter",
  UnsubscribeFromMatter: "UnsubscribeFromMatter",
}

const EXPORT_TAG = {
  id: 151,
  keyCode: 115,
};

const MAIN_TAG_ID_LIST = [1, 2, 3];

const NO_DATABASE = "No Database";

export {
  TYPE_CLICK,
  QUICK_SELECT_ITEM,
  PARAMETERS,
  COMMON_TEXT,
  METHOD_API,
  PATH_NAME,
  PAGE_NAME,
  DATA_INFO_LABEL,
  OPTION_SORT,
  DISPLAY_TYPE,
  AXIOS_RESPONSE_CODE,
  EVENT_KEY_LISTENER,
  OPTION_SELECT_MESSAGE,
  KEY_CODE,
  PATHNAME_ITEMS,
  TYPE_ICON,
  USER_AGENT_TEXT,
  ARCHIVE_TYPE,
  EVENT_LISTENER,
  ARCHIVE_ACTION,
  COLORS,
  PRINT_SCREEN_NAME,
  TIME,
  PERCENTAGE,
  PAGINATION,
  ROLE_BASED_EMAIL,
  IMPORT_TYPE,
  ENVIRONMENT_SITE,
  DEFAULT_NAME,
  TOAST_MESSAGE_IMPORT_DATA,
  TOAST_MESSAGE_IMPORT_NATIVES,
  TOAST_MESSAGE_INDEX_REBUILD_STARTED,
  TOAST_MESSAGE_INDEX_REBUILD_DONE,
  SIGNALR_CLIENT_FUNCTIONS,
  SIGNALR_SERVER_FUNCTIONS,
  EXPORT_TAG,
  MAIN_TAG_ID_LIST,
  NO_DATABASE,
  ARCHIVE_MSG,
  ARCHIVE_STATUS,
  STATUS_IMPORT_BLOB,
  DROPDOWN_LABEL
};
