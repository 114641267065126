import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import InfoCallLog from "./info-call-log/InfoCallLog";
import InfoVoiceMail from "./info-voice-mail/InfoVoiceMail";
import InfoImage from "./info-image/InfoImage";
import InfoVideo from "./info-video/InfoVideo";
import InfoNotes from "./info-notes/InfoNotes";
import InfoHistoryLink from "./info-history-link/InfoHistoryLink";
import InfoFile from "./info-file/InfoFile";
import InfoLocation from "./info-location/InfoLocation";
import InfoChatEmail from "./info-chat-email/InfoChatEmail";
import InfoContact from "./info-contact/InfoContact";
import InfoDatabase from "./info-database/InfoDatabase";
import InfoAudio from "./info-audio/InfoAudio";
import InfoNetwork from "./info-network/InfoNetwork";
import InfoFinancial from "./info-financial/InfoFinancial";
import RecordControl from "./info-controls/record-control/RecordControl";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import DownloadAttachmentButton from "components/shared/download-attachment-button/DownloadAttachmentButton";

// Constants
import { CHAT_EMAIL_TYPE, DATA_TYPE } from "constants/DataType";
import { DISPLAY_TYPE } from "constants/Common";

// Helpers
import { getNameByDataSource } from "helpers/RenderInformationHelper";

// Styles
import styles from "./InformationContainer.module.scss";
import { useParams } from "react-router-dom";

// Store
import { setDisableViewerActions } from "store/ViewerReducer";

const InformationContainer = ({
  loading,
  reviewType,
  data,
  filterData = {},
  recordControls = {},
  totalRecords,
  displayType,
  showRecordControls = true,
  isImgNotFound = false,
  docID = "",
}) => {
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const dataSourceList = useSelector(
    (state) => state.dataSource.dataSourceList
  );

  const {
    generalReviews = [],
  } = useSelector((state) => state.generalRV);

  const currentRecord = generalReviews.find(record => record.docID === docID) || {};
  const currentCustodian = getNameByDataSource(currentRecord.dataSourceID, dataSourceList);

  let isDownloadFile = false;
  const renderResultInfo = (type, displayType = "") => {
    const isPreviewSearch = displayType === DISPLAY_TYPE.preview;
  
    const enableDownload = () => {
      isDownloadFile = true;
    };
  
    const componentMap = {
      [DATA_TYPE.callLog]: <InfoCallLog callLogInfo={data} />,
      [DATA_TYPE.voicemail]: <InfoVoiceMail voiceMailInfo={data} />,
      [DATA_TYPE.image]: <InfoImage imageInfo={data} displayType={displayType} />,
      [DATA_TYPE.video]: <InfoVideo videoInfo={data} displayType={displayType} />,
      [DATA_TYPE.note]: <InfoNotes noteInfo={data} />,
      [DATA_TYPE.historyLink]: <InfoHistoryLink historyLinkInfo={data} />,
      [DATA_TYPE.file]: <InfoFile fileInfo={data} />,
      [DATA_TYPE.location]: <InfoLocation locationInfo={data} />,
      [DATA_TYPE.database]: <InfoDatabase databaseInfo={data} />,
      [DATA_TYPE.contact]: <InfoContact contactInfo={data} />,
      [DATA_TYPE.audio]: <InfoAudio audioInfo={data} />,
      [DATA_TYPE.network]: <InfoNetwork networkInfo={data} />,
      [DATA_TYPE.creditCard]: <InfoFinancial financialInfo={data} />,
      [DATA_TYPE.chat]: (
        <InfoChatEmail
          itemData={{ 
            custodianName: currentCustodian, 
            sourceApplication: currentRecord.source, 
            ...currentRecord, 
            ...data 
          }}
          filterData={filterData}
          isPreviewSearch={isPreviewSearch}
        />
      ),
      [DATA_TYPE.chatGroup]: (
        <InfoChatEmail
          itemData={data}
          filterData={filterData}
          isPreviewSearch={isPreviewSearch}
        />
      ),
      [DATA_TYPE.email]: (
        <InfoChatEmail
          itemData={data}
          filterData={filterData}
          isPreviewSearch={isPreviewSearch}
        />
      ),
      [DATA_TYPE.emailThread]: (
        <InfoChatEmail
          itemData={data}
          filterData={filterData}
          isPreviewSearch={isPreviewSearch}
        />
      ),
    };
  
    switch (type) {
      case DATA_TYPE.voicemail:
      case DATA_TYPE.image:
      case DATA_TYPE.video:
      case DATA_TYPE.file:
      case DATA_TYPE.database:
      case DATA_TYPE.audio:
        enableDownload();
        break;
      default:
        break;
    }
  
    return componentMap[type] || null;
  };  

  useEffect(() => {
    dispatch(setDisableViewerActions(filterData?.previewLoading))
  }, [filterData?.previewLoading]);

  return (
    <div
      className={clsx(
        styles["is-result-info-wrap"],
        displayType === DISPLAY_TYPE.preview ? styles["info-wrap"] : ""
      )}
    >
      <div
        className={clsx(
          "card-body p-4",
          styles["is-result-card"],
          displayType === DISPLAY_TYPE.preview
            ? styles["is-result-card-preview"]
            : ""
        )}
      >
        <div className={styles["is-result-info-title"]}>
          <h5>Information</h5>
          {docID && showRecordControls && (
            <div className={styles["record-control"]}>
              <RecordControl
                recordID={docID}
                showRecordID={true}
                disablePrevious={recordControls.disablePrevious}
                disableNext={recordControls.disableNext}
                handleRecordChange={recordControls.handleRecordChange}
              />
            </div>
          )}
        </div>
        <div className={clsx(styles["is-info-wrap"])}>
          {loading && !CHAT_EMAIL_TYPE.includes(data?.type) ? (
            <div className={styles["loading-info"]}>
              <Spinner variant="primary" animation="border" />
            </div>
          ) : data === null || totalRecords === 0 ? (
            <EmptyPage
              size={displayType === DISPLAY_TYPE.preview ? "md" : ""}
              messages="No results found. Please try again."
              isImgnotFound={isImgNotFound}
            />
          ) : (
            <div className={styles["result-info-container"]}>
              {renderResultInfo(reviewType, displayType)}
              <div>
                <DownloadAttachmentButton 
                  matterId={projectId}
                  attachmentId={data?.id}
                  hidden={!isDownloadFile}
                  isIcon={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

InformationContainer.propTypes = {
  docID: PropTypes.string,
  reviewType: PropTypes.string,
  displayType: PropTypes.string,
  totalRecords: PropTypes.number,
  isImgNotFound: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.object,
  filterData: PropTypes.object,
  recordControls: PropTypes.object,
  showRecordControls: PropTypes.bool,
};

export default InformationContainer;
