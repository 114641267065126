import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";

// Constants & Helpers
import { DATA_SOURCE_TYPES } from "constants/Constants";
import { padWithLeadingZeros } from "helpers/FormatterHelper";
import { getTypeIconSrc } from "helpers/CommonHelper";

// Styles
import styles from "./DataSourceInformation.module.scss";

const DataSourceInformation = ({
  dataSources = [],
  isLoading = false,
  isTable = false,
  isShowCollapse = true,
  isShowTitle = true,
}) => {

  return (
    <div className={clsx(styles["wrap"], isTable ? styles["table-view"] : "")}>
      <div>
        {!isLoading ? (
          dataSources?.length > 0 ? (
            <ul className={styles["data-source-list"]}>
              {dataSources.map((item) => (
                <li key={item.dataSourceID}>
                  <img
                    className={styles["data-source-icon"]}
                    src={getTypeIconSrc(
                      DATA_SOURCE_TYPES,
                      item.dataSourceTypeName
                    )}
                    alt="Data source icon"
                  />
                  <div className={styles["data-source-item"]}>
                    <p
                      title={`${item.deviceName} - ${item.custodianName}`}
                    >{`${item.deviceName} - ${item.custodianName}`}</p>
                    <span>
                      {`SOURCEID_${padWithLeadingZeros(item.dataSourceID, 5)}`}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className={styles["empty-page"]}>No data source found.</p>
          )
        ) : (
          <div className={styles["spinner-container"]}>
            <SpinnerLoading />
          </div>
        )}
      </div>
    </div>
  );
};

DataSourceInformation.propTypes = {
  isLoading: PropTypes.bool,
  isTable: PropTypes.bool,
  isShowCollapse: PropTypes.bool,
  isShowTitle: PropTypes.bool,
  dataSources: PropTypes.arrayOf(
    PropTypes.shape({
      dataSourceID: PropTypes.number,
      custodianName: PropTypes.string,
      dataSourceTypeName: PropTypes.string,
      deviceName: PropTypes.string,
    })
  ),
};

export default DataSourceInformation;
