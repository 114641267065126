import store from "../store/index";

export const clampedAll = async ({
  array = [],
  clamp = 1,
  onProgress = () => {},
}) => {
  const results = [];
  let nextIndex = 0;

  const processItem = async () => {
    while (nextIndex < array.length) {
      const currentIndex = nextIndex++;
      const execution = array[currentIndex];

      const state = store.getState();
      if (state.importBlob.isCancelImport) {
        throw new Error('Upload canceled');
      }

      const result = await execution();
      results.push(result);
      onProgress(result);
    }
  };

  const workers = Array(Math.min(clamp, array.length))
    .fill()
    .map(() => processItem());

  await Promise.all(workers);
  return results;
};
