import { STATUS_IMPORT_BLOB } from "constants/Common";

export const convertDataSourceInfo = (allDataSource = [], listSourceId = []) =>
  allDataSource?.filter((item) =>
    listSourceId?.includes(item.dataSourceID.toString())
  );

export const handleTotalFileImport = (files = []) => {
  if (files.length === 0) return;
  const bytes = files.map((item) => item?.size || 0);
  const blobBytes = bytes?.reduce((x, y) => x + y) || 0;
  const blobCount = bytes?.length || 0;
  return { blobBytes, blobCount };
};

export const findIdCanImport = (importQueue = [], currentImportId) => {
  const importsReady = importQueue.filter(
    (item) => item.status === STATUS_IMPORT_BLOB.ready && item.id !== currentImportId
  );
  return importsReady.length > 0 ? importsReady[0].id : "";
};
