import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { COMMON_TEXT } from "constants/Common";

const numFormatter = (number) => {
  let newNumber = number;
  if (number > 1000000000) newNumber = (number / 1000000000).toFixed(1) + "B";
  else if (number > 1000000) newNumber = (number / 1000000).toFixed(1) + "M";
  else if (number > 1000) newNumber = (number / 1000).toFixed(1) + "K";
  return newNumber;
};

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes"

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const padWithLeadingZeros = (num, totalLength) =>
  String(num).padStart(totalLength, "0");

/**
 * Get previous element of an array, return null if first element
 * @param array arr
 * @param any value
 * @returns
 */
const previousElement = (arr, value) => {
  const index = arr.indexOf(value);
  if (index <= 0) return null;
  return {
    index: index - 1,
    value: arr[index - 1],
  };
};

/**
 * Get next element of an array, return null if last element
 * @param array arr
 * @param any value
 * @returns
 */
const nextElement = (arr, value) => {
  const index = arr.indexOf(value);
  if (index >= 0 && index < arr.length - 1) {
    return {
      index: index + 1,
      value: arr[index + 1],
    };
  }
  return null;
};

const renderPhoneNumber = (phoneNumber) =>
  !isPossiblePhoneNumber(phoneNumber)
    ? phoneNumber
    : parsePhoneNumber(phoneNumber).formatInternational();

const checkDuplicatePhoneNumber = (phoneNumber1, phoneNumber2) => {
  const phoneNumber1Format = phoneNumber1
    .trim()
    .replace("+", "")
    .replace(" ", "");
  const phoneNumber2Format = phoneNumber2
    .trim()
    .replace("+", "")
    .replace(" ", "");
  return !!(phoneNumber1Format === phoneNumber2Format);
};

const uppercaseFirstLetterEachWord = (str) => {
  if (!str) return;
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

const handleStringToolong = (str = "", numberSlice) =>
  str?.length > numberSlice ? `${str.slice(0, numberSlice)}...` : str;

const sortTagList = (tagList = []) =>
  [...tagList].sort((tagA, tagB) => tagB.tagID - tagA.tagID);

const toThousandsNumber = (value) =>
  isNaN(parseInt(value)) ? value : parseInt(value).toLocaleString("en-US");

const convertToMegabytes = (value) =>
  isNaN(parseInt(value)) ? value : (value / (1024 * 1024)).toFixed(2);

const getDuration = (originalDuration) =>
  originalDuration?.slice(0, 8) || "00:00:00";

const renderStrongTag = (string) => `<strong>${string}</strong>`;

const isJsonStructure = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const sortAlphabetListTag = (array) =>
  array?.length > 0 && array.sort((a, b) => a.name.localeCompare(b.name));

const formatShowNumber = (number) => {
  if (number === null) return COMMON_TEXT.default;
  if (number === 0) return 0;
  return Number(number);
};

export {
  numFormatter,
  previousElement,
  nextElement,
  renderPhoneNumber,
  checkDuplicatePhoneNumber,
  uppercaseFirstLetterEachWord,
  handleStringToolong,
  sortTagList,
  toThousandsNumber,
  getDuration,
  renderStrongTag,
  formatBytes,
  padWithLeadingZeros,
  isJsonStructure,
  sortAlphabetListTag,
  formatShowNumber,
  convertToMegabytes
};
