// Helpers
import { toThousandsNumber, convertToMegabytes } from "helpers/FormatterHelper";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getDeletedStatus } from "./TextHelper";
import { getDuration } from "helpers/FormatterHelper";

// Constants
import { PARAMETERS } from "constants/Common";
import { DATE_TIME_TYPE } from "constants/Constants";

export const getInformationByKey = (key, data) => {
  const value = data[key] ?? "";

  switch (key) {
    case PARAMETERS.type:
    case PARAMETERS.typeLabel:
    case PARAMETERS.id:
    case PARAMETERS.custodianName:
      return value;

    case PARAMETERS.attachmentFileSize: {
      const megabytes = convertToMegabytes(value);
      return megabytes != null ? `${Number(megabytes).toLocaleString()} MB` : null;
    }
          
    case PARAMETERS.description:
      return value.trim();

    case PARAMETERS.sourceApplication:
      return convertToTitleCase(value);

    case PARAMETERS.totalMessage:
    case PARAMETERS.itemsCount:
    case PARAMETERS.messageCount:
      return toThousandsNumber(value);

    case PARAMETERS.dateTime:
    case PARAMETERS.createdDate:
    case PARAMETERS.updatedDate:
    case PARAMETERS.lastConnection:
    case PARAMETERS.lastAutoConnection:
    case PARAMETERS.dateLastUsed:
    case PARAMETERS.expirationDate:
    case PARAMETERS.creationTime:
    case PARAMETERS.dateFirst:
    case PARAMETERS.dateLast:
    case PARAMETERS.lastVisited:
    case PARAMETERS.startTime:
    case PARAMETERS.endTime:
      return formatDateTime({
        dateTime: value,
        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
      });

    case PARAMETERS.duration:
      return getDuration(value);

    case PARAMETERS.deletedState:
      return getDeletedStatus(value);

    case PARAMETERS.latitude:
    case PARAMETERS.longitude:
      return value === 0 ? 0 : value;

    default:
      return value;
  }
};

const convertToTitleCase = (string) => string.replace(/^\w/, c => c.toUpperCase());

export const getNameByDataSource = (id, sources) => 
  sources.find((source) => source?.dataSourceID?.toString() === id?.toString())?.custodianName;
