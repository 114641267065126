import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";

// Styles
import clsx from "clsx";
import styles from "./EmojiCloud.module.scss";

const EmojiCloud = ({
  data,
  isLoading,
  onClick,
  isTable,
  listConversation = [],
}) => {
  const [opacityPerOneLevel, setOpacityPerOneLevel] = useState(0);
  const [newData, setNewData] = useState(data || []);

  useEffect(() => {
    let total = 0; // Sum of all total icon
    let levelTemp = []; // To group level based on number of occurrences of the icon
    if (data) {
      data.forEach((item) => {
        if (!levelTemp.includes(item.count)) {
          total += 1;
          levelTemp.push(item.count);
        }
      });
      levelTemp = levelTemp.reverse();
      setOpacityPerOneLevel(1 / total);
      setNewData(
        data.map((item) => ({
          ...item,
          level: levelTemp.indexOf(item.count) + 1,
        }))
      );
    }
  }, [data]);

  /**
   * DEFAULT OPACITY: 0.2
   */
  const getOpacityByLevel = (level) => {
    let opacity = (opacityPerOneLevel * level).toFixed(2);
    return opacity <= 0.2 ? 0.2 : opacity;
  };

  return (
    <div
      className={clsx(
        styles["emoji-container"],
        isTable && styles["emoji-container-table-view"]
      )}
    >
      {!isLoading ? (
        <div className={styles["information-emoji-cloud"]}>
          {listConversation?.length > 0 && newData?.length > 0 ? (
            <div className={styles["information-emoji-cloud-list"]}>
              {newData?.map((word, wordIndex) => (
                <div key={wordIndex} className={styles["emoji-item"]}>
                  <span
                    className={styles["emoji"]}
                    style={{
                      opacity: `${getOpacityByLevel(word?.level)}`,
                    }}
                    onClick={() => onClick(word?.value)}
                  >
                    {word?.value}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p className={styles["empty-page"]}>No emojis found.</p>
          )}
        </div>
      ) : (
        <div className={styles["spinner-container"]}>
          <Spinner
            className={styles["spinner-loading"]}
            animation="border"
            variant="primary"
            role="status"
          />
        </div>
      )}
    </div>
  );
};

EmojiCloud.propTypes = {
  isLoading: PropTypes.bool,
  isBorder: PropTypes.bool,
  isTable: PropTypes.bool,
  data: PropTypes.array,
  listConversation: PropTypes.array,
  onClick: PropTypes.func,
};

export default EmojiCloud;
