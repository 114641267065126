import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import InformationTabContainer from "../info-tab-container/InformationTabContainer";

// Constants
import { PARAMETERS } from "constants/Common";
import { CHAT_INFORMATION } from "constants/DataType";

// Styles
import styles from "./InfoChatEmail.module.scss";

const InfoChatEmail = ({
  itemData = {},
  filterData = {},
  isPreviewSearch = false,
}) => {
  return (
    <div
      className={clsx(
        styles["wrap"],
        isPreviewSearch ? styles["wrap-preview"] : ""
      )}
    >
      <InformationTabContainer
      infoResultLabelList={CHAT_INFORMATION}
      dataType={itemData[PARAMETERS.type]}
      itemInfo={itemData}
      filterData={filterData}
    />
    </div>
  );
};

InfoChatEmail.propTypes = {
  itemData: PropTypes.object,
  filterData: PropTypes.object,
  isPreviewSearch: PropTypes.bool
};

export default InfoChatEmail;
