// Helper
import { clampedAll } from "./ImportPromiseAllHelper";
import store from "store/index";

import {
  uploadNativeFileChunk,
  nativeFileCommit,
  uploadLoadfileBlock,
  loadfileCommit
} from "services/ImportFileService";

let controller = new AbortController();

export const uploadFileToBlob = async (params) => {
  const handleCancelled = () => {
    const state = store.getState();
    if (state.importBlob.isCancelImport) {
      controller.abort();
      controller = new AbortController();
      return true;
    }
    return false;
  };

  const { 
    matterId, 
    dataSourceId, 
    files, 
    folderName,
    onBlockComplete = () => {} 
  } = params;

  if (!files?.length) return [];

  const options = {
    abortSignal: controller.signal,
  };

  const allBlocks = files.flatMap(file => {
    const filePath = file.webkitRelativePath
      ? file.webkitRelativePath.replace(`${folderName}/`, "")
      : file.path.replace(`/${folderName}/`, "");

    return getFileBlocks(matterId, dataSourceId, filePath, file);
  });

  try {
    if (handleCancelled()) return [];

    const fileBlockMap = new Map();
    const completedBlocksMap = new Map();
    
    allBlocks.forEach(block => {
      if (!fileBlockMap.has(block.filePath)) {
        fileBlockMap.set(block.filePath, []);
        completedBlocksMap.set(block.filePath, []);
      }
      fileBlockMap.get(block.filePath).push(block.blockId);
    });

    const uploadCalls = allBlocks.map(block => async () => {
      await uploadNativeFileChunk(
        matterId,
        dataSourceId,
        block.filePath,
        block.blockId,
        block.data,
        {
          ...options,
          blobHTTPHeaders: { blobContentType: block.type }
        }
      );

      return {
        blockId: block.blockId,
        filePath: block.filePath,
        size: block.data.size,
        name: block.name
      };
    });

    const MAX_CONCURRENCY = 10;
    const results = await clampedAll({
      array: uploadCalls,
      clamp: MAX_CONCURRENCY,
      onProgress: async (result) => {
        onBlockComplete(result);

        const { filePath, blockId } = result;
        completedBlocksMap.get(filePath).push(blockId);

        const fileBlocks = fileBlockMap.get(filePath);
        const completedBlocks = completedBlocksMap.get(filePath);
        
        if (fileBlocks.length === completedBlocks.length) {
          if (handleCancelled()) return;
          await nativeFileCommit(matterId, dataSourceId, filePath, fileBlocks);
        }
      }
    });

    return results;

  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getFileBlocks = (matterId, dataSourceId, filePath, file) => {
  const blockSize = 4 * 1024 * 1024; // 4MB
  const blockCount = Math.ceil(file.size / blockSize);
  const blocks = [];
  for (let i = 0; i < blockCount; i++) {
    const start = i * blockSize;
    const end = Math.min(start + blockSize, file.size);
    const blockId = "block-" + i.toString().padStart(13, "0");

    blocks.push({
      matterId,
      dataSourceId,
      blockId,
      filePath,
      name: file.name,
      type: file.type,
      data: file.slice(start, end),
    });
  }

  return blocks;
};

// Handle upload with file size larger
export const uploadLoadFile = async ({
  matterId,
  dataSourceId,
  file,
  setRemainingTime,
  loadFileType
}) => {
  const fileSize = file.size;
  const blobName = file.name;
  const blockSize = 20 * 1024 * 1024; // 20MB
  const blockCount = Math.ceil(fileSize / blockSize);
  const startTime = Date.now();

  // calculate remaining time default before get remaining time real-time
  const defaultUploadRate = 2 * 1024 * 1024;
  const remainingTimeDefault = Math.floor(fileSize / defaultUploadRate);
  setRemainingTime(remainingTimeDefault);

  const blockIds = [];
  for (let i = 0; i < blockCount; i++) {
    const start = i * blockSize;
    const end = Math.min(start + blockSize, fileSize);
    const sizeAfterUp =
      (i + 1) * blockSize > fileSize ? fileSize : (i + 1) * blockSize;
    const chunk = file.slice(start, end);
    const blockId = "block-" + i.toString().padStart(6, "0");
    await uploadLoadfileBlock(matterId, dataSourceId, blobName, blockId, chunk);
    blockIds.push(blockId);

    // calculate remaining time real-time when after file size uploaded
    const endTime = Date.now();
    const elapsedTime = (endTime - startTime) / 1000;
    const uploadRate = elapsedTime / sizeAfterUp;
    const remainingTime = Math.floor((fileSize - sizeAfterUp) * uploadRate);
    setRemainingTime(remainingTime);
  }

  try {
    await loadfileCommit(matterId, dataSourceId, blobName, blockIds, loadFileType);
  } catch (error) {
    console.error(error);
  }
};
