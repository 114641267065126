import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  viewerLoading: false,
  disableViewerActions: false,
};

const viewerSlice = createSlice({
  name: "viewer",
  initialState,
  reducers: {
    setViewerLoading: (state, action) => {
        state.viewerLoading = action.payload;
    },
    setDisableViewerActions: (state, action) => {
        state.disableViewerActions = action.payload;
    },
  },
});

export const {
    setViewerLoading,
    setDisableViewerActions
} = viewerSlice.actions;

export const ViewerReducer = viewerSlice.reducer;
